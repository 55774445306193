import React from "react"

import Fade from "react-reveal/Fade"

import "./HomeBanner.scss"

const HomeBanner = () => {
  return (
    <Fade>
      <section className="home-banner"></section>
    </Fade>
  )
}

export default HomeBanner
