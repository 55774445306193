import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductList from "../components/products/ProductList"
import HomeBanner from "../components/sections/HomeBanner"
import ProductBanner from "../components/sections/ProductBanner"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const {
    products,
    featuredCollection,
    firstCollection,
    secondCollection,
    shop,
  } = data.saleor

  return (
    <>
      <SEO title={shop.name} path="/" />
      {featuredCollection?.products?.edges?.length ? (
        <ProductBanner collection={featuredCollection} />
      ) : null}
      <ProductList title="Our Products" products={products} />
      {firstCollection?.products?.edges?.length ? (
        <ProductList
          title={firstCollection.name}
          products={firstCollection.products}
        />
      ) : null}
      {secondCollection?.products?.edges?.length ? (
        <ProductList
          title={secondCollection.name}
          products={secondCollection.products}
        />
      ) : null}
      <HomeBanner />
    </>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    saleor {
      shop {
        name
      }
      featuredCollection: collection(slug: "featured-collection") {
        name
        descriptionJson
        products(first: 10, filter: { stockAvailability: IN_STOCK }) {
          edges {
            node {
              id
              slug
              name
              descriptionJson
              visibleInListings
              isAvailable
              isAvailableForPurchase
              images {
                url
                alt
              }
            }
          }
        }
      }
      firstCollection: collection(slug: "home-collection-1") {
        name
        descriptionJson
        products(first: 10, filter: { stockAvailability: IN_STOCK }) {
          edges {
            node {
              id
              slug
              name
              descriptionJson
              visibleInListings
              isAvailable
              isAvailableForPurchase
              pricing {
                discount {
                  gross {
                    amount
                    currency
                  }
                }
              }
              thumbnail {
                url
                alt
              }
              variants {
                name
                id
                pricing {
                  price {
                    gross {
                      amount
                      currency
                    }
                  }
                }
              }
            }
          }
        }
      }
      secondCollection: collection(slug: "home-collection-2") {
        name
        descriptionJson
        products(first: 10, filter: { stockAvailability: IN_STOCK }) {
          edges {
            node {
              id
              slug
              name
              descriptionJson
              visibleInListings
              isAvailable
              isAvailableForPurchase
              pricing {
                discount {
                  gross {
                    amount
                    currency
                  }
                }
              }
              thumbnail {
                url
                alt
              }
              variants {
                name
                id
                pricing {
                  price {
                    gross {
                      amount
                      currency
                    }
                  }
                }
              }
            }
          }
        }
      }
      products(first: 10, filter: { stockAvailability: IN_STOCK }) {
        edges {
          node {
            id
            slug
            name
            visibleInListings
            isAvailable
            isAvailableForPurchase
            pricing {
              discount {
                gross {
                  amount
                  currency
                }
              }
            }
            variants {
              name
              id
              pricing {
                price {
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage
