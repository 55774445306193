import React from "react"

import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"

import { parseContentJson } from "../../utils"
import Button from "../common/Button"
import Link from "../common/Link"

import "./ProductBanner.scss"

const ProductBanner = ({ collection }) => {
  const { name, products } = collection

  const [selectedProduct, setSelectedProduct] = React.useState(0)

  React.useEffect(() => {
    let sliderInterval

    if (products.edges.length) {
      sliderInterval = setInterval(() => {
        if (selectedProduct === products.edges.length - 1) {
          setSelectedProduct(0)
        } else {
          setSelectedProduct(selectedProduct + 1)
        }
      }, 3000)
    }

    return () => {
      if (sliderInterval) {
        clearInterval(sliderInterval)
      }
    }
  }, [selectedProduct, products?.edges?.length])

  const { node: product } = products.edges[selectedProduct]

  return (
    <Fade>
      <section className="product-banner">
        <div className="product-banner__container">
          <div className="product-banner__details">
            <div className="product-banner__details__name">{name}</div>
            <div className="product-banner__details__description">
              <div className="product-banner__details__description__title">
                {product.name}
              </div>
              <article
                className="product-banner__details__description__desc"
                dangerouslySetInnerHTML={{
                  __html: parseContentJson(product.descriptionJson),
                }}
              />
              <Link to={`/product/${product.slug}`}>
                <Button color="primary" isLarge>
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
          <figure className="product-banner__image">
            <img
              src={product.images[0].url}
              alt={product.images[0].alt}
              loading="lazy"
            />
          </figure>
        </div>
      </section>
    </Fade>
  )
}

ProductBanner.propTypes = {
  collection: PropTypes.object.isRequired,
}

export default ProductBanner
